<template>
  <div class="about">
    <h1>This is an about page</h1>
    <button v-on:click="onTestBtnClicked">返回上一个页面</button>
  </div>
</template>

<script>

export default {
  name: 'About',
  methods: {
    onTestBtnClicked: function() {
      this.$router.back(-1)
    }
  }
}
</script>